/* 726 TECHNOLOGY INC 2017 - 2022 */


/* GLOBAL ELEMENT STYLES */

button:hover {
    cursor: pointer;
}


/* COLOR FILTERS */

.FBBlueSVG {
    filter: invert(30%) sepia(15%) saturate(2389%) hue-rotate(179deg) brightness(96%) contrast(91%);
}

.LightSilverSVG {
    filter: invert(95%) sepia(0%) saturate(772%) hue-rotate(173deg) brightness(68%) contrast(112%);
}

.LightSkyBlue {
    filter: invert(77%) sepia(9%) saturate(2111%) hue-rotate(172deg) brightness(99%) contrast(97%);
}

.LiveRedSVG {
    filter: invert(40%) sepia(63%) saturate(3918%) hue-rotate(335deg) brightness(87%) contrast(109%);
}

.OrangeSVG {
    filter: invert(75%) sepia(37%) saturate(5197%) hue-rotate(359deg) brightness(101%) contrast(105%);
}

.ShadowSVG {
    filter: invert(49%) sepia(4%) saturate(0%) hue-rotate(221deg) brightness(93%) contrast(95%);
}


/* USABLE PAGE */

.BodyBox {
    width: 92%;
    height: 94%;
    background-color: transparent;
    position: absolute;
    bottom: 4%;
    top: 2%;
    right: 4%;
    left: 4%;
    box-sizing: border-box;
    min-height: 15vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.BodyBox::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .BodyBox {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


/* PAGE TITLE */

.TitleBox {
    font-weight: bold;
    padding: 0.5% 6%;
    background-color: transparent;
    font-size: 2.5vh;
    letter-spacing: 2px;
}

.header-logo {
    height: 100px;
    width: 100px;
}

@media screen and (min-width: 1100px) {
    .TitleBox {
        padding: 0 2vw;
        font-size: 3.5vh;
    }
}

@media screen and (max-width: 720px) {
    .BodyBox {
        display: block;
        overflow: auto;
    }
    .TitleBox__Text {
        font-size: 18px !important;
    }
    /* h5.TitleBox__Text{
        font-size: 14px !important;
        padding: 4px;
        
    } */
    h5.TitleBox__Text {
        font-size: 11px !important;
        padding: 0px;
        margin-top: 6px;
    }
    .header-logo {
        height: 75px;
        width: 100px;
    }
    .AreaHeader {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }
    .AreaSelect {}
}

.TitleBox__Text {
    color: ghostwhite;
    padding: 0.8vh 0 0.1vh 0;
    font-family: 'Papyrus';
}


/* PAGE CONTENT */

.ContentBox {
    display: inline;
}

@media screen and (min-width: 1100px) {
    .ContentBox {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        height: 40vh;
    }
    .ContentBox.long {
        height: 75vh;
    }
}


/* LANDSCAPE SPLITTER */

.HalfBox {
    background-color: transparent;
    box-sizing: border-box;
    padding: 0 6%;
    width: 100%;
}

@media screen and (min-width: 1100px) {
    .HalfBox {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
    .HalfBox.center {
        align-items: center;
    }
}


/* LEFT SIDE */


/* CONTENT VISIBILITY GROUPING */

.ContentForm {
    width: 100%;
}


/* STANDARD TEXT */

.StandardText {
    font-size: 2.0vh;
    color: ghostwhite;
}

@media screen and (min-width: 1100px) {
    .StandardText {
        font-size: 2.5vh;
    }
}

.RightPadded {
    padding-right: 1vw;
}


/* META WRAPPER */

.MetaWrapper {
    margin-top: 4vh;
}


/* META DATA */

.MetaData {
    font-size: 3.0vh;
    font-family: monospace;
    color: #000;
}

.MetaData.mini {
    font-size: 1.5vh;
}

.MetaData.medium {
    font-size: 2.0vh;
}

.MetaData.maxi {
    font-size: 4vh;
}

.MetaData.white {
    color: #fff;
}

@media screen and (min-width: 1100px) {
    .MetaData.medium {
        font-size: 3.0vh;
    }
}


/* FIELD ITEM */

.FieldItem {
    display: block;
    padding: 0 2%;
    margin-top: 4vh;
    box-sizing: border-box;
    width: 100%;
}

.FieldItem input {
    width: 100%;
    padding: 1vh 2vw;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 4vh;
    letter-spacing: 3px;
    outline: none !important;
}

.FieldItem .condensed {
    font-size: 2vh;
}

.FieldItem__Select {
    width: 100%;
    padding: 1vh 2vw;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 4vh;
    letter-spacing: 3px;
    text-align-last: left;
    margin-top: 4vh;
}

.FieldItem__Select option.invalid {
    background-color: #ffefef;
}


/* BUTTON VISIBILITY GROUPING */

.ButtonGroup {
    width: 100%
}

.ButtonGroup.centered {
    text-align: center;
}

.ButtonGroup.centered {
    text-align: center;
}

.ButtonGroup button {
    box-sizing: border-box;
    font-weight: bold;
    font-size: 4vh;
    letter-spacing: 3px;
    margin-top: 4vh;
}

.ButtonGroup input {
    box-sizing: border-box;
    font-weight: bold;
    font-size: 4vh;
    letter-spacing: 3px;
    margin-top: 4vh;
    padding: 1vh 0;
}

.ButtonGroup.compact input,
.ButtonGroup.compact button {
    margin-top: 2vh;
}

.ButtonGroup.condensed input,
.ButtonGroup.condensed button {
    font-size: 2vh;
}

.ButtonGroup.dense input,
.ButtonGroup.dense button {
    margin-top: 1vh;
}

.AlphaLight input,
.AlphaLight button {
    transition: background-color 0.125s;
    background-color: lightskyblue;
    color: #333;
    border-radius: 10px;
    border: none;
    outline: none !important;
}

.AlphaLight .tapped {
    background-color: #777;
}


/* BUTTON LINE SHARING */

.ButtonSplitter {
    display: flex;
    justify-content: center;
}

.ButtonSplitter:not(:first-child) {
    margin-top: 4vh;
}

.ButtonLarge {
    width: 100%;
    left: 5%;
    right: 5%;
    bottom: 5%;
}

.ButtonHalf {
    width: 50%;
}

.ButtonHalf.margined {
    margin-left: 8%;
}

.ButtonThird {
    width: 33%;
}

.ButtonThird.margined {
    margin-left: 8%;
}

.ButtonGroup.compact .ButtonSplitter:not(:first-child) {
    margin-top: 2vh;
}

.ButtonGroup.dense .ButtonSplitter:not(:first-child) {
    margin-top: 1vh;
}


/* BUTTON ICONS DISABLED */

button:disabled .ButtonIconImg {
    filter: invert(58%) sepia(96%) saturate(0%) hue-rotate(133deg) brightness(92%) contrast(86%);
}


/* BUTTON SAVE */

.ButtonSave {
    color: #333;
}

.ButtonSave .ButtonIconImg {
    filter: invert(77%) sepia(9%) saturate(2111%) hue-rotate(172deg) brightness(99%) contrast(97%);
}


/* BUTTON DELETE */

.ButtonDelete {
    color: darkred;
}

.ButtonDelete .ButtonIconImg {
    filter: invert(7%) sepia(76%) saturate(7493%) hue-rotate(10deg) brightness(94%) contrast(111%);
}

.ButtonDelete.selected {
    background-color: #777;
}


/* TILES */


/* TILE SVG */

.TileSVG {
    width: 7vw;
    height: 7vh;
    margin-top: 1vh;
    transition: filter 0.5s;
    filter: invert(16%) sepia(0%) saturate(1%) hue-rotate(325deg) brightness(100%) contrast(89%);
}

.TileSVG.save {
    filter: invert(77%) sepia(9%) saturate(2111%) hue-rotate(172deg) brightness(99%) contrast(97%);
}

.TileSVG.delete {
    filter: invert(7%) sepia(76%) saturate(7493%) hue-rotate(10deg) brightness(94%) contrast(111%);
}


/* TILE SIZES */

.TileHalf {
    width: 50%;
    text-align: center;
}

.TileHalf:not(.disabled):hover .TileSVG {
    filter: invert(49%) sepia(4%) saturate(0%) hue-rotate(221deg) brightness(93%) contrast(95%);
    cursor: pointer;
}

.TileHalf:not(.disabled):hover .TileSVG.save {
    filter: invert(30%) sepia(15%) saturate(2389%) hue-rotate(179deg) brightness(96%) contrast(91%);
}

.TileThird {
    width: 33%;
    text-align: center;
}

.TileThird:not(.disabled):hover .TileSVG {
    filter: invert(49%) sepia(4%) saturate(0%) hue-rotate(221deg) brightness(93%) contrast(95%);
    cursor: pointer;
}

.TileThird:not(.disabled):hover .TileSVG.save {
    filter: invert(30%) sepia(15%) saturate(2389%) hue-rotate(179deg) brightness(96%) contrast(91%);
}

.TileThird:not(.disabled):hover .TileSVG.delete {
    filter: invert(7%) sepia(76%) saturate(7493%) hue-rotate(10deg) brightness(300%) contrast(111%);
}

.TileHalf.selected,
.TileThird.selected {
    background-color: #777;
}

.disabled .TileSVG {
    filter: invert(95%) sepia(0%) saturate(772%) hue-rotate(173deg) brightness(68%) contrast(112%);
}


/* DATA LINES */

.DataContainer {
    display: block;
    background-color: #00000099;
    padding: 1vh 0;
    margin: 3vh 0;
}

.DataLine {
    color: #fff;
    font-size: 3vh;
    margin: 1vh 0.5vw 0.5vh 0;
    padding: 0.75vh 1.25vw;
}

@media screen and (min-width: 1100px) {
    .DataLine {
        font-size: 3.5vh;
        margin: 1vh 1vw;
        padding: 1vh 2vw;
    }
}

.DataLine.header {
    border-bottom: 2px solid #fff;
    margin-bottom: 3vh;
}


/* TEXT FIELD */

.TextField {
    display: block;
    margin: 2vh 0 0 0;
    box-sizing: border-box;
}

.TextField input {
    padding: 1vh 2vw;
    box-sizing: border-box;
    width: 100%;
    font-weight: bold;
    font-size: 2vh;
    letter-spacing: 3px;
    background-color: lightskyblue;
    border: none;
    border-radius: 6px;
    outline: none !important;
    margin: 0 0.5vw 0.5vh 0;
}

@media screen and (min-width: 1100px) {
    .TextField input {
        margin: 1vh 1vw;
        padding: 1vh 2vw;
        width: 95%;
        font-size: 3vh;
    }
}


/* TEXT BOLD */

.TextBold {
    font-weight: bold;
    text-shadow: 0 0 0.4vh lightskyblue, 0 0 0.8vh lightskyblue;
}


/* TEXT LINK */

.TextLink {
    color: ghostwhite;
    text-decoration: underline;
    font-weight: bold;
    transition: text-shadow 0.5s ease;
}

.TextLink:hover {
    color: ghostwhite;
    cursor: pointer;
    text-shadow: 0 0 0.4vh lightskyblue, 0 0 0.8vh lightskyblue;
}

.TextLink:active,
.TextLink:visited {
    color: ghostwhite;
}


/* FB LOGIN BUTTON GROUP */

#fb-login-button-group-div {
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
}