// body {
//     --theme-primary: #30256a;
//     --theme-primary-6: #30256aaa;
//     --theme-primary-3: #30256a33;
//     --theme-white: white;
//     --theme-white-op: #ffffffaa;
//     --theme-black: black;
// }


//   **********************************************Domains Style Setting START **************************************************
@import '@ctrl/ngx-emoji-mart/picker';
/* Style for Gemmonkey.com */
.Gem-Monkey {
    --theme-primary: #30256a !important;
    --theme-primary-6: #30256aaa  !important;
    --theme-primary-3: #30256a33  !important;
    --theme-white: white  !important;
    --theme-white-op: #ffffffaa  !important;
    --theme-black: black  !important;
    .ToolBarBox {
                background-color: var(--theme-primary)!important;
          
        }

        .background-logo {
           
            opacity: 0.25 !important;
         
        }
  }
  
  /* Style for thetucsongemshow.live */
  .TheTucson-GemShow {
    --theme-primary: #30073a  !important;
    --theme-primary-6: #30073a66  !important;
    --theme-primary-3: #30073a33  !important;
    --theme-white: white  !important;
    --theme-white-op: #ffffffaa  !important;
    --theme-black: black  !important;
    .ToolBarBox {
        background-color: var(--theme-primary)!important;
  
}

// Chat room start **********************************

.chat-room{
    background-color: var(--theme-primary)!important;
}
// CHat room end *************************
  }

//  register privacy label
.labelColor{
    color: black !important;
}




  /* Style for sunwest-silver */

  .Sunwest-Silver {
    --theme-primary: #672746 !important;
    --theme-primary-6: #9f7890  !important;
    --theme-primary-3: #654b5c  !important;
    --theme-white: white  !important;
    --theme-white-op: black  !important;
    --theme-black: black  !important;
    .ToolBarBox {
                background-color: var(--theme-primary)!important;
          
        }

        .background-logo {
           
            opacity: 0.1 !important;
         
        }
// style for componet tables
        table.table.mainTable {
            color: var(--theme-white);
        }


        .MetaData.mini.white {
            color: var(--theme-white) !important;
        }
        .StandardText.RightPadded {
            color: var(--theme-white) !important;
        }

        .cartList {
            color: var(--theme-white) !important;
        }
       

        // style for show speacial list discount count
        .ResponsiveHalfDealBox.centerstart.clear {
            color: var(--theme-white) !important;
        }

        // style for strong tile
        .titleLoginStyle{
            color: var(--theme-white) !important;
        }


        .forget-color{
            color: white !important;
        }

        .TileLabel{
            color: var(--theme-white) !important;
        }
        // order entry total
        .sunTotalOrder{
            color: var(--theme-white) !important;
        }
//  row color green of table and black of fonts
.bg-green td {
    background-color: #efffef;
    color: black;
}

.labelColor{
    color: white !important;
}

// Data table not found css
.Data_Not_Found{
    color: white;
}

// Home page left side opacity
.login-text-opacity{
    opacity: 0.6 !important;
}


  }



//   **********************************************Domains Style Setting END **************************************************
.pointer {
    cursor: pointer;
}

.Light {
    background-color: aliceblue !important;

    * {
        color: black !important;
    }

    .ResponsiveHalfBox.cropped {
        background-color: #626293c5;
    }
}

.POSField input {
    color: grey;

    &:focus {
        background-color: #fafafa;
        border-color: rgba(118, 118, 118, 0.3);
        color: grey;
    }
}

table> :not(caption)>*>* {
    /*padding: 0.5rem 0.5rem;
    background-color: white !important;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px rgb(240, 240, 240) !important;*/
}

.head-icon {
    filter: invert(1);
    width: 24px;
    height: 24px;
    margin: 4px;
}

.ng-select.AreaSelect {
    border: 0px;
    min-height: 0px;
    border-radius: 0;
    margin: 20px;
    background-color: white;
}

.ng-select.AreaSelect .ng-select-container {
    min-height: 0px;
    border-radius: 0;
    background-color: white;
}

ng-dropdown-panel .scroll-host {
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    display: block;
    background-color: white;
    -webkit-overflow-scrolling: touch;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
    display: block;
    height: auto;
    box-sizing: border-box;
    // max-height: 240px;
    max-height: 340px;
    overflow-y: auto;
}

ngb-tooltip-window {
    .tooltip-arrow {
        display: none !important;
    }

    .tooltip-inner {
        font-size: 16px;
        font-weight: 900;
        border: 3px solid #30073a;
        background-color: #30073a;
        border-radius: 20px;
    }
}

.Tile {
    background-color: var(--theme-primary-3);
}

.Tile:hover {
    background-color: var(--theme-primary-6);
    // background-color:#30256aaa;

    cursor: pointer;

    div {
        padding: 4px;
    }
}

.Header_Title {
    color: var(--theme-primary);
    font-size: 4vh;
    font-family: monospace;
}

.ToolBarBox {
    background-color: var(--theme-primary);
    color: var(--theme-white);
}

.TileLabel {
    font-family: monospace;
    color: var(--theme-black);
}

.AreaSelect {
    background-color: var(--theme-primary-3);
}

.AreaActionButtons .AreaActionButton.conditional {
    background-color: var(--theme-primary-3);
}

.AreaSelect option,
.AreaSelect option:not(:checked) {
    background-color: var(--theme-white) !important;
    color: var(--theme-black);
}

.body-theme {
    background-color: var(--theme-white-op);

    * {
        font-family: monospace;
    }
}

.background-logo {
    /*background-image: url(assets/images/The-Tucson-Gem-Show-Logo-Final-grayscale.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    
    background-position: center;
    background-size: 400px;*/
    z-index: -1;
    position: absolute;
    top: 60%;
    opacity: 0.06225;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50vh;
    width: 50vh;
}

.MetaData.white {
    color: var(--theme-black);
}

.ResponsiveHalfBox {
    background-color: var(--theme-primary-3);
    border-color: var(--theme-primary-6);

    * {
        color: var(--theme-black) !important;
    }
}

.AreaActionButton {
    border: 1px;
    border-radius: 8px;
}

.CustomeSelect {
    width: 100%;
    // max-width: 150px;
    max-width: 290px !important;
    // background-color: var(--theme-primary-6);
    background-color: #d6cdd7;
    padding: 12px;
    // padding: 2vh 2vw;
    // padding-top: 2vh;
    // padding-right: 2vw;
    // padding-bottom: 2vh;
    // padding-left: 2vw;
    // margin-left: 10px;

    .ng-option {
        padding: 6px;
        white-space: nowrap !important;
    }

    .ng-value {
        padding-left: 6px;
        color: var(--theme-black);
        font-weight: 400;
    }
}

.CustomeSelectRmbg {
    width: 100%;
    max-width: 150px;

    .ng-option {
        padding: 6px;
    }

    .ng-value {
        padding-left: 6px;
        color: var(--theme-white);
        font-weight: 400;
    }
}

tr.selected td {
    background-color: var(--theme-primary-3);
}

span.ng-clear-wrapper {
    margin-left: 7px;
}

ng-dropdown-panel {}

app-order-entry {
    ng-select.CustomeSelect {
        width: 100%;
        max-width: 100% !important;
        // background-color: transparent !important;
        border: 1px solid #000;
        padding: 14px;
    }

    ng-select.CustomeSelect .ng-select-container {
        // display: initial !important;
    }

    ng-select.CustomeSelect ng-dropdown-panel {
        top: 45px !important;
    }

    ng-select.CustomeSelect .ng-dropdown-panel-items.scroll-host {
        top: 0 !important;
        left: -16px !important;
        border: 1px solid black;
    }

    .ng-select .ng-has-value .ng-placeholder {
        display: none;
    }

    // .CustomeSelect {
    //     width: 100%;
    //     min-width: 100% !important;
    //     // padding: 16px !important;
    //     padding: 20px 15px 7px !important;
    //     line-height: 23px;
    //     background-color: #e3e3e3 !important;
    //     color: #000 !important;
    //     /*ng-dropdown-panel {
    //         margin-left: -18px !important;
    //     }*/
    //     .ng-option {
    //         padding: 6px !important;
    //         color: black !important;
    //     }
    //     .ng-value {
    //         padding-left: 0px !important;
    //         height: 100% !important;
    //         color: #000 !important;
    //       /*  margin-top: 11px !important;*/
    //     }
    // }
    .ng-select .ng-clear-wrapper {
        margin-top: 6px;
        position: absolute;
        right: 0;
        top: 0;
    }

    // .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    //     position: absolute;
    //     left: 0;
    //     width: 100%;
    //     padding: 15px;
    //     top: 0;
    // }
    .ng-select .ng-select-container .ng-value-container .ng-input>input {
        padding: 0 !important;
        margin: 0 !important;
    }

    .ng-value {
        color: #000 !important;
        line-height: 30px;
        padding: 0px 0;
    }

    // .currency.ng-dirty.ng-touched.ng-valid {
    //     padding: 1.5vh 3vw;
    //   }
    .currency.ng-dirty.ng-touched.ng-invalid {
        padding: 1.5vh 2.5vw;
    }
}

.tbl-head-icons {
    height: 14px;
}

@media screen and (max-width: 720px) {
    .AreaHeader {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }

    .AreaHeader>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }

    .AreaSelect {
        margin: 4px auto;
        padding: 12px auto;
    }

    .AreaSelectBox {
        img {
            display: none;
        }
    }

    .AreaSelectBox.right {
        min-width: 25vw;
        justify-content: center;
    }
}

.print {
    /* hide the printing component from @media screen */
    display: none;
}

@media print {

    /* invert the display (show/hide) properties of the main */
    /* aplication component and the printing component       */
    .print {
        display: block;
    }

    body {
        height: 100mm;
        width: 78mm;
    }

    .non-print {
        display: none;
    }

    @page {
        size: auto;
        margin: 0mm;
        width: 79mm;
        height: 100mm;
        padding: auto;
    }
}

div#doubleDialog {
    display: contents !important;
}

// Table improve css  **************************
.cm-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.cm-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.cm-scrollbar::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.cm-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #919191;
}

.cm-table-w-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    max-height: 65vh;
}

.cm-table-w-scroll table {
    width: 100%;
    margin: auto;
    // border-collapse: separate;
    border-spacing: 0;
}

.cm-table-w-scroll table th,
.cm-table-w-scroll table td {
    font-size: 13px;
    padding: 16px 3px;
    vertical-align: top;
    white-space: nowrap;
}

.cm-table-w-scroll thead th {
    background: #b7b7b7;
    font-size: 15px;
    /* set background color for the overcome transparent header cell */
    color: black;
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    top: 0;
    font-weight: 600;
}

.cm-table-w-scroll tfoot,
.cm-table-w-scroll tfoot th,
.cm-table-w-scroll tfoot td {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    bottom: -1px;
    background: #eee;
    /* set background color for the overcome transparent header cell */
    color: black;
    font-size: 12px;
    z-index: 4;
    box-shadow: 0 0 5px 0 rgba(90, 90, 90, 0.15);
}

//****************************
.bg-green td {
    background-color: #efffef;
}

//  deals list and deals input css

.dealNameWordRap {
    font-size: 18px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mic-icon:hover,
.mic-icon:focus,
.mic-icon .mic-icon {
    opacity: 0.5;
}



.mic-icon {
    animation: pulse 1s infinite paused;

    /* Add animation property */
    width: 20px !important;
    position: absolute !important;
    right: -7px !important;
    top: 17px !important;
    cursor: pointer !important;

    line-height: 1;
    top: calc(50% - 0.9rem);
    border: 0;
    border-radius: 0.25rem;
    // opacity: 0.7;
    transition: opacity 0.3s;
}

.mic-icon.active {
    animation-play-state: running;

    /* Start the animation */
    filter: invert(46%) sepia(93%) saturate(6000%) hue-rotate(338deg) brightness(100%) contrast(101%);

}

@keyframes pulse {

    /* Existing keyframe styles */
    0%,
    100% {
        opacity: 1;
        width: 30px;
    }

    50% {
        opacity: 0.2;
        width: 20px;
    }
}

.image-preview{
    max-width: 100% !important;
    min-height: 100vh;
    width: 100vw;
    height: 100vh;
    margin: 0;
    .close{
        background: #0003;
        border: 0;
        height: 60px;
        width: 60px;
        position: sticky;
    }
}



// Chat Application UI Start *********************************
/* styles.css */
@import '~bootstrap/dist/css/bootstrap.min.css';

/* Custom styles for chat application */
.chat-window {
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.message-bubble {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

.text-right .message-bubble {
  margin-left: auto;
  margin-right: 0;
}

.bg-primary.text-white {
  color: white !important;
}


// Chat Application UI END *********************************END 
