.widthAll {
    max-width: 100% !important;
}

/*
body{
    *{
        color: black!important;
    }

    background-color: #0000 !important;
}*/
