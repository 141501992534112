/* 726 TECHNOLOGY INC 2017 - 2022 */
.ConsoleOutputData { font-size: 2.0vh; line-height: 2.5vh; font-family: monospace; color: ghostwhite; }
@media screen and (min-width: 1100px) {
    .ConsoleOutputData { font-size: 3.0vh; line-height: 3.5vh; }
}

.ConsoleOutputData a, .ConsoleOutputData a:visited, .ConsoleOutputData a:active { color: ghostwhite; }
.ConsoleOutputData a.white, .ConsoleOutputData a.white:visited, .ConsoleOutputData a.white:active { color: ghostwhite; }
.ConsoleOutputData a:hover, .ConsoleOutputData a.white:hover { color: #fff; }

.ConsoleOutputData a.ex, .ConsoleOutputData a.ex:visited, .ConsoleOutputData a.white:ex { color: lightskyblue; }

.ResponsiveContentBox { height: 100%; display: flex; flex-wrap: wrap; }
@media screen and (min-width: 1100px) {
    .ResponsiveContentBox { height: 76vh; }
}   

.ResponsiveContextBox.centered { align-items: center; }

/* LANDSCAPE SPLITTER */
.ResponsiveHalfBox { background-color: #0e0e26c5; box-sizing: border-box; margin-top: 2vh; 
                     padding: 0 6%; display: flex; align-items: center; width: 100%; 
                     border: calc(0.25vh + 0.25vw) solid #0e0e2655; }
@media screen and (min-width: 1100px) {
    .ResponsiveHalfBox { width: 50%; height: 100%; }
}  

.ResponsiveHalfBox.centerstart { align-items: flex-start; }
@media screen and (min-width: 1100px) {
    .ResponsiveHalfBox.centerstart { align-items: center; }
}  

.ResponsiveHalfBox.cropped { max-height: 30vh; }
@media screen and (min-width: 1100px) {
    .ResponsiveHalfBox.cropped { max-height: 40vh; }
}  

.ResponsiveHalfBox.clear { background-color: transparent; border: none; }

.ResponsiveHalfBox.extended { min-height: 60vh; max-height: 60vh; overflow-y: scroll; }
@media screen and (min-width: 1100px) {
    .ResponsiveHalfBox.extended { max-height: 80vh; }
}  

.ResponsiveHalfBox.footer { align-items: flex-end; max-height: 14vh; margin-top: 0; }
@media screen and (min-width: 1100px) {
    .ResponsiveHalfBox.footer { align-items: center; max-height: 80vh; margin-top: 2vh; }
}  

.ResponsiveHalfBox.scrolling { scrollbar-width: none; }
.ResponsiveHalfBox.scrolling::-webkit-scrollbar { display: none; }

.ResponsiveHalfBox.unpadded { padding: 0; }
.ResponsiveHalfBox.wrapped { flex-wrap: wrap; -webkit-flex-wrap: wrap; }

/* SUB BOX */
.SubBox { width: 100%; }

/* FIELD ITEMS */
.DashboardFieldItem { display: block; padding: 0 2%; margin-top: 4vh;
                      box-sizing: border-box; width: 100%; }
.DashboardFieldItem.nopadding { padding: 0; }
.DashboardFieldItemInput { width: 100%; padding: 1vh 2vw; box-sizing: border-box;
                           font-weight: bold; font-size: 2vh; letter-spacing: 3px;
                           outline: none !important; }  
.DashboardFieldItemInput.tall { padding: 3vh 2vw; }

/* DEAL ITEMS */
.DealItemWrapper { color: #fff; display: flex; align-items: center; justify-content: space-between; margin: 2vh 0;
                   background-color: #3333337f; padding: 1vh 1vw; font-size: 1.5vh; }
@media screen and (min-width: 1100px) {
    .DealItemWrapper { font-size: 2vh; }
}  
.DealItemImgBox { width: 25%; }
.DealItemImage { width: 100%; height: auto; }
.DealItemImage.clickable:hover { cursor: pointer; opacity: 1.0; }
.DealItemImage.clickable.disabled { opacity: 0.5; }
.DealItemImage.clickable.disabled:hover { cursor: default; }
.DealItemImage.clickable.tmpdisabled { opacity: 0.5; }
.DealItemImage.clickable.tmpdisabled:hover { cursor: default; }
.DealItemBtnBox { width: 10%; display: flex; flex-direction: column; }
.DealItemText { width: 47%; text-align: left; padding: 0 4vw; }
.DealItemPrice { width: 18%; text-align: right; font-family: monospace; }
.DealItemPriceAmount { font-family: sans-serif; font-weight: bold;
                       font-size: 2.5vh; letter-spacing: 2px; 
                       text-shadow: #0000007f 0.5vh 0.5vh; 
                       animation: color-change 10s infinite; }
.DealItemQtyInput { width: 30%; min-width: 25px; background-color: #fff; }
@media screen and (min-width: 1100px) {
    .DealItemQtyInput { min-width: 40px; }
}  
.DealItemQtyInput.positive { background-color: #afa; }
@keyframes color-change {
  0% { color: white; }
  12% { color: azure; }
  24% { color: lightskyblue; }
  36% { color: azure; }
  48% { color: white; }
  60% { color: azure; }
  72% { color: lightskyblue; }
  84% { color: azure; }
  96% { color: white; }
}