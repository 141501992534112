/* 726 TECH TOOLBAR 2017 - 2021 */
/* PAGE TOOLBAR */
.ToolBarBox { background-color: #802d07aa; padding: 0 6%; font-size: 1vh;
    display: flex; justify-content: space-between; border: calc(0.25vh + 0.25vw) solid #802d0755; }
.ToolBarBox{
    background-color: #ffffff;
    padding: 0 6%;
    font-size: 1vh;
    display: flex;
    justify-content: space-between;
    border: calc(0.25vh + 0.25vw) solid #f3f3ff66;
    border-radius: 16px;
}

@media screen and (min-width: 1100px) {
.ToolBarBox { padding: 0 2vw;  }
}
.ToolBarButtonBank { display: flex; justify-content: flex-end; 
           min-width: 150px; max-width: 40vw; }
.ToolBarButtonBank.reverse { justify-content: flex-start; }
.ToolBarButton { background-color: transparent; margin-left: 4vw; display: flex; align-items: center; }
.ToolBarButton.shared { margin-left: 2vw; margin-right: 0; }
.ToolBarButton.reverse { margin-left: 0; margin-right: 2vw; }
.ToolBarButton__Icon { width: 3.0vw; height: 3.5vh; margin-top: 0.25vh; margin-bottom: 0.25vh; 
             min-width: 40px; transition: opacity 1s; opacity: 0.75; }
.ToolBarButton:hover .ToolBarButton__Icon { opacity: 1.0; }
.ToolBarButton__Image { width: 3.0vw; height: 4.0vh; margin-top: 0; min-width: 40px; 
              transition: filter 1s;
              filter: invert(95%) sepia(0%) saturate(772%) 
              hue-rotate(173deg) brightness(68%) contrast(112%); }
.ToolBarButton:hover .ToolBarButton__Image { filter: invert(76%) sepia(30%) saturate(750%) 
                                   hue-rotate(174deg) brightness(96%) contrast(103%); }
.ToolBarText { font-size: 1.5vh; font-family: monospace; margin-top: 1.4vh;
     font-weight: bold; text-align: right; color: #eee; }