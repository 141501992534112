/* 726 TECH 2017 - 2022 */
/* PAGE BUTTONS */
/* PAGE BUTTON SVG */
.PageButtonSplitter { display: flex; justify-content: space-around; }
.PageButtonSplitter:first-child { margin-bottom: 4vh; }
.PageButtonSVG { height: 7vh; margin-top: 1vh; transition: filter 0.5s;
                 filter: invert(69%) sepia(79%) saturate(203%) 
                         hue-rotate(155deg) brightness(99%) contrast(87%); }
.PageButtonSVG.save { filter: invert(77%) sepia(9%) saturate(2111%) 
                      hue-rotate(172deg) brightness(99%) contrast(97%); }
.PageButtonSVG.delete { filter: invert(7%) sepia(76%) saturate(7493%) 
                        hue-rotate(10deg) brightness(94%) contrast(111%); }
.PageButtonHalf { width: 50%; text-align: center; }
.PageButtonHalf:not(.disabled):hover .PageButtonSVG { filter: invert(69%) sepia(79%) saturate(203%) 
                      hue-rotate(155deg) brightness(75%) contrast(87%);
                           cursor: pointer; }
.PageButtonHalf:not(.disabled):hover .PageButtonSVG.save { filter: invert(30%) sepia(15%) saturate(2389%) 
                                               hue-rotate(179deg) brightness(96%) contrast(91%); }
.PageButtonThird { width: 33%; text-align: center; }
.PageButtonThird:not(.disabled):hover .PageButtonSVG { filter: invert(69%) sepia(79%) saturate(203%) 
                           hue-rotate(155deg) brightness(75%) contrast(87%); cursor: pointer; }
.PageButtonThird:not(.disabled):hover .PageButtonSVG.save { filter: invert(30%) sepia(15%) saturate(2389%) 
                                                hue-rotate(179deg) brightness(96%) contrast(91%); }
.PageButtonThird:not(.disabled):hover .PageButtonSVG.delete { filter: invert(7%) sepia(76%) saturate(7493%) 
                           hue-rotate(10deg) brightness(300%) contrast(111%); }
.PageButtonHalf.selected, .PageButtonThird.selected { background-color: #777; }
.disabled .PageButtonSVG { filter: invert(95%) sepia(0%) saturate(772%) 
                           hue-rotate(173deg) brightness(68%) contrast(112%); }
/* CUSTOM BUTTON SVG */
.CustomButtonSVG { height: 11vh; width: 11vh; margin-top: 1vh; opacity: 0.5; transition: opacity 0.5s; }
.CustomButtonSVG.compact { height: 8vh; width: 8vh; }
@media screen and (min-width: 1100px) {
    .CustomButtonSVG { height: 12vh; width: 12vh; }
    .CustomButtonSVG.compact { height: 12vh; width: 12vh; }
}
.CustomButtonSVG:hover { opacity: 1.0; }
.CustomButtonSVG.clickable:hover { cursor: pointer; }
.CustomButtonThird { width: 33%; text-align: center; }